
.education-wrapper {
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .education-header {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    .el-breadcrumb {
      line-height: 40px;
    }
    .operation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      .category .label{
        margin-right: 10px;
      }
    }
  }
}
.el-link + .el-link {
  margin-left: 10px;
}
/deep/ p {
  margin: 0;
}
/deep/ .el-table__row .is-checked .el-checkbox__inner,
/deep/ .has-gutter .el-checkbox .is-indeterminate .el-checkbox__inner,
/deep/ .has-gutter .el-checkbox .is-checked .el-checkbox__inner {
  background-color: #1122d8;
  border-color: #1122d8;
}
